import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Card } from 'react-bootstrap'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

import Seo from '../components/seo'
import Layout from '../components/layout'
import CTA from '../components/cta'
import Quote from '../components/quote'
import { StaticImage } from 'gatsby-plugin-image'

import SoftwareDevelopmentIcon from '../icons/software-development.svg'
import MultipleUsersIcon from '../icons/multiple-users.svg'
import WebsiteDesignIcon from '../icons/website-design.svg'
import MaintenanceOperationsIcon from '../icons/scheduled-maintenance.svg'
import ExpertsIcon from '../icons/experts-multicolor.svg'
import RelatableIcon from '../icons/relatable-multicolor.svg'
import CollaborativeIcon from '../icons/collaborative-multicolor.svg'
import ReliableIcon from '../icons/reliable-multicolor.svg'
import LightbulbBrainIcon from '../icons/lightbulb-brain-multicolor.svg'
import SnapFingersIcon from '../icons/snap.svg'
import classNames from 'classnames'
import NextProject from '../components/next-project'

import AboutHero1 from '../images/about-us/about.hero.1.png'
import AboutHero2 from '../images/about-us/about.hero.2.png'
import AboutHero3 from '../images/about-us/about.hero.3.png'
import AboutHero4 from '../images/about-us/about.hero.4.png'
import AboutHeroWhite1 from '../images/about-us/about.hero.white.1.png'
import AboutHeroWhite2 from '../images/about-us/about.hero.white.2.png'
import AboutHeroWhite3 from '../images/about-us/about.hero.white.3.png'
import AboutHeroWhite4 from '../images/about-us/about.hero.white.4.png'

const About = ({ location }) => {
  const [activeHeroIndex, setActiveHeroIndex] = useState(0)
  const breakpoints = useBreakpoint()
  const whatWeDoOptions = [
    {
      id: 0,
      logo: <SoftwareDevelopmentIcon height={breakpoints.md ? 110 : 70} />,
      title: 'Software Development',
      description: 'Digital products that people love',
      link: '/development-services',
    },
    {
      id: 1,
      logo: <MultipleUsersIcon height={breakpoints.md ? 110 : 62} />,
      title: 'Professional Services',
      description: 'Increasing efficiency for any system in your business',
      link: '/services',
    },
    {
      id: 2,
      logo: <WebsiteDesignIcon height={breakpoints.md ? 110 : 70} />,
      title: 'Design',
      description: "User experiences that tell your brand's story",
      link: '/design-services',
    },
    {
      id: 3,
      logo: <MaintenanceOperationsIcon height={breakpoints.md ? 110 : 70} />,
      title: 'Maintenance & Operations',
      description: 'Prevent systems failures that can cripple businesses',
      link: '/maintenance-and-operations',
    },
  ]

  const ourValuesOptions = [
    {
      logo: <ExpertsIcon height={80} />,
      title: 'Expert',
      description: 'We guide you past pitfalls with our experience, adaptable process, and support.',
    },
    {
      logo: <RelatableIcon height={80} />,
      title: 'Relatable',
      description: 'We build honest, strong relationships so we can be helpful and ride out bumps in the road together.',
    },
    {
      logo: <CollaborativeIcon height={80} />,
      title: 'Collaborative',
      description: 'Your success is our success, so we make customizing your project and working with us really easy. And we have some fun while we’re at it.',
    },
    {
      logo: <ReliableIcon height={80} />,
      title: 'Reliable',
      description: 'Whenever, wherever, and however you need us—we’re working hard for you in relevant ways and for the long haul.',
    },
    {
      logo: <LightbulbBrainIcon height={80} />,
      title: 'Adaptable',
      description: 'We balance creativity with feasibility- we’re hopeful, confident, flexible, and open to new ideas.',
    },
  ]

  const heroImages = [
    { src: AboutHero1, altSrc: AboutHeroWhite1, id: 0 },
    { src: AboutHero2, altSrc: AboutHeroWhite2, id: 1 },
    { src: AboutHero3, altSrc: AboutHeroWhite3, id: 2 },
    { src: AboutHero4, altSrc: AboutHeroWhite4, id: 3 },
  ]

  useEffect(() => {
    if (activeHeroIndex >= heroImages.length - 1) {
      setTimeout(() => {
        setActiveHeroIndex(0)
      }, 3000)
    } else {
      setTimeout(() => {
        setActiveHeroIndex((prevState) => prevState + 1)
      }, 3000)
    }
  }, [activeHeroIndex])

  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title='About the 729 Solutions Team'
        description='We are software engineers, developers, designers, project managers, and outside-of-the-box thinkers. A one-stop-shop, you might say, and proud of it.'
      >
        <meta property='article:modified_time' content='2021-07-21T18:38:49+00:00' />
        <meta name='twitter:label1' content='Est. reading time' />
        <meta name='twitter:data1' content='17 minutes' />
      </Seo>
      <section id='hero' className='bg-pattern-trihex'>
        <div className='container'>
          <div className='row about-hero'>
            <div className='col-md-5 col-lg-6 border-primary'>
              <div className='text-panel row'>
                <div id='image-container' className='about-hero-mobil-container col-sm-12 d-md-none d-lg-none pt-3 d-flex justify-content-center'>
                  {heroImages.map((image) => (
                    <img
                      src={image.altSrc}
                      key={image.id}
                      className={`about-hero-image ${activeHeroIndex !== image.id ? 'hidden' : ''}`}
                      alt='About Hero Image'
                    />
                  ))}
                </div>
                <h1 className='text-white text-unset bg-primary mb-0 col-9 col-lg-12'>
                  Application software developers, software creators, designers, UI/UX experts...
                </h1>
              </div>
            </div>
            <div className='col-md-7 col-lg-6 m-auto text-center d-none d-sm-none d-md-flex align-items-center'>
              {heroImages.map((image) => (
                <img src={image.src} key={image.id} className={`about-hero-image ${activeHeroIndex !== image.id ? 'hidden' : ''}`} alt='About Hero Image' />
              ))}
            </div>
          </div>
        </div>
      </section>
      <section id='begin' className='container'>
        <div className='py-5 py-md-6'>
          <div className='row'>
            <div className='col-12 text-center pb-5 m-auto'>
              <h2 className='mb-0 text-unset'>We’re our clients’ unfair advantage</h2>
            </div>
          </div>
          <div className='col-12 col-lg-6 col-md-10 m-auto'>
            <Quote
              quote={`“729’s communication is better than anything I’ve experienced with other developers.”`}
              name='Stuart Harris'
              company='Alta Service'
            />
          </div>
          <div className='col-12 pt-4 text-center'>
            <CTA href='/contact-us' text='Learn More' className='btn btn-primary' />
          </div>
        </div>
      </section>
      <section id='caring' className='d-flex bg-gray-200 '>
        <div className='container'>
          <div className='py-5 row'>
            <div className='col-12 col-md-10 col-lg-12 m-auto text-center px-md-5'>
              <h2 className='text-unset mb-3'>Caring about our customers isn’t optional</h2>
            </div>
            <div className='col-12 col-lg-10 text-center m-auto px-md-5'>
              <p>
                <strong>Our founder</strong> hated working in cutthroat, impersonal cultures, and wanted to start{' '}
                <strong>
                  a small, intimate company where everyone cared about their customers and each other and worked hard to build really cool things.
                </strong>{' '}
                So...he did. Read our full history <Link to='/company-history'>here.</Link>
              </p>
              <p>
                We still believe in what motivated him to start 729: we should work to live, not live to work. We can be creative and serious and quirky all at
                the same time.
              </p>
              <p>
                <strong>And we can be experts at what we do, so our clients win again and again.</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='what-we-do'>
        <div className='container py-5 px-4'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h2 className='pb-3'>What We Do</h2>
            </div>
            {whatWeDoOptions.map(({ logo, title, description, link }, index) => (
              <div className='col-12 col-md-6 col-xl-3' key={`option-${index}`}>
                <Card className='custom-cards p-4'>
                  <div>{logo}</div>
                  <Card.Body className='d-flex flex-column p-0 pt-3'>
                    <Card.Title as='h5' className='heading-font-family px-4 my-3'>
                      {title}
                    </Card.Title>
                    <Card.Text className='mt-0'>{description}</Card.Text>
                    <CTA href={link} text='Learn More' className='btn btn-primary my-0 px-4' />
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section id='every-company' className='bg-gray-100'>
        <div
          className={classNames('container py-5 px-5', {
            'w-75': breakpoints.lg,
          })}
        >
          <div className='row'>
            <div className='col-12 text-center'>
              <h2>Every company...</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-9 text-center mb-5 mr-auto ml-auto'>
              <p>
                <strong>...starts with a problem to solve</strong>
              </p>
              <p>
                <strong>Ours was simple:</strong> we disagreed with the way a lot of projects were run at the time- bloated budgets and timelines, unclear
                communication, and ultimately, sub-par products or solutions.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-12 col-md-9 text-center mr-auto ml-auto'>
              <h2 className='text-unset'>Projects done on time and on budget</h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 col-lg-9 text-center mr-auto ml-auto'>
              <p>
                <strong>Could we do it better?</strong>
              </p>
              <p>
                When the client doesn’t know what they don’t know…it’s easy for consultancies to sell them things they don’t need and churn out products or
                solutions that really don’t address the problems that the client came to them with.
              </p>
              <p>Or worse, they can promise a solution, get halfway there after burning through the budget, and then leave the client hanging.</p>
              <p className='mt-4'>
                <strong>We started 729 to come up with an alternative. </strong>
              </p>
              <p>How? By creating a radical velocity-based agile framework, guiding clients past common pitfalls, and constantly growing and learning.</p>
              <p>
                <strong>We’re able to complete higher-quality, better-performing projects on time and on budget… so yes, we CAN do it better.</strong>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id='we-help' className='bg-dark-transparent'>
        <div className='container py-5 px-4'>
          <div
            className={classNames('row', {
              'w-lg-75 mx-auto': breakpoints.md,
            })}
          >
            <div className='col-12 col-md-9 col-lg-8 m-auto text-center px-4'>
              <h2 className='text-white text-unset'>We help you have a disruption-proof business</h2>
            </div>
            <div className='col-12 col-lg-8 m-auto text-center'>
              <p className='text-white'>That's why we keep you informed and engaged all the way through your project.</p>
              <p className='text-white'>
                We created our Integrated Quality (IQ) framework to help us deliver higher-quality, better-performing projects on time and on budget.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className='our-values bg-gray-200'>
        <div className='container-fluid py-5'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h2>Our Values</h2>
            </div>
            <div className='col-12 mx-auto'>
              <p className='desc text-center mb-5 mx-auto'>
                We believe that creating business solutions should be easy and fun, even when it's challenging.
                <br />
                It should leave you happy and excited, your project on budget and on time.
                <br /> And it should leave you with a better product than you ever thought possible.
              </p>
            </div>
          </div>
          <div className='row'>
            <div className='col-11 mx-auto'>
              <div className='row justify-content-center mx-n2 mx-n1'>
                {ourValuesOptions.map(({ logo, title, description }, index) => (
                  <div
                    className='col-10 col-md-4 col-lg-3 px-2 px-1 align-items-stretch'
                    key={index}
                    style={{
                      maxWidth: breakpoints.xl ? '230px' : 'initial',
                    }}
                  >
                    <Card className='card custom-cards'>
                      <Card.Body className='py-4 px-3'>
                        <div className='mb-3'>{logo}</div>
                        <Card.Title as='h3' className='card-title mb-3 mb-md-1'>
                          {title}
                        </Card.Title>
                        <Card.Text className='card-text'>{description}</Card.Text>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <NextProject className='next-project mb-5' />
    </Layout>
  )
}

export default About
